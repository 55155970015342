import React, { useRef, useEffect } from "react";
import { styled } from '@mui/material/styles';
import {
  Box,
  Typography,
} from '@mui/material';

import bg from "../images/misc/invesco.png";

const Container = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  textAlign: 'center',
  gap: '30px',
})

const Logo = styled("img")({
  width: "min(50%, 280px)",
  height: 'auto',
});

const ModuleName = styled(Typography)({
  fontFamily: 'CircularStd-Medium',
  fontSize: "clamp(1.875rem, 1.6549rem + 0.939vw, 2.5rem)",
  letterSpacing: "-0.4px",
});

const LearningObjective = ({ moduleName } : { moduleName: string }) => {
  const audioRef = useRef<HTMLAudioElement | null>(null);

  useEffect(() => {
    const audioElement = audioRef.current;

    const loadAudio = async () => {
      if (audioElement) {
        try {
          // Define the audio source URL based on the moduleName
          const response = await fetch(`${process.env.REACT_APP_ASSETS_URL}/modules/${moduleName}/title_screen_vo.mp3`);

          if (!response.ok) {
            throw new Error(`Failed to fetch audio: ${response.status}`);
          }

          // Create a blob from the response data
          const audioBlob = await response.blob();

          // Create an object URL from the blob
          const audioSource = URL.createObjectURL(audioBlob);

          // Set the audio source
          audioElement.src = audioSource;

          // Autoplay the audio when the component mounts
          audioElement.play().catch((error) => {
            console.error('Audio playback error:', error);
          });
        } catch (error) {
          console.error('Error loading audio:', error);
        }
      }
    };

    loadAudio();
  }, [moduleName]);

  return (
    <Container>
      <Logo src={bg} alt="" />
      <ModuleName>{moduleName}</ModuleName>
      <audio ref={audioRef} controls style={{ display: 'none' }} />
    </Container>
  );
}

export default LearningObjective;