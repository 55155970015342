import React from "react";
import { styled } from '@mui/material/styles';
import {
  Box,
  Typography,
  Button,
} from '@mui/material';

const Container = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "45px",
  alignItems: "center",
  width: "min(100%, 840px)",
  margin : "0 auto",
  "@media (max-width:375px)": {
    gap: "20px",
  }
})

const QuestionText = styled(Typography)({
  fontSize: "clamp(1.5625rem, 1.4525rem + 0.4695vw, 1.875rem)",
  lineHeight: "40px",
  "@media (max-width:375px)": {
    lineHeight: "30px",
  }
})

const ConfidenceRateContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: "13px",
  width: "100%",
  "@media (max-width:375px)": {
    flexDirection: 'row-reverse',
  }
})

const ConfidenceRateButtonsBlock = styled(Box)({
  display: 'flex',
  flexWrap: "wrap",
  alignItems: 'center',
  gap: "10px",
  width: "100%",
  "@media (max-width:375px)": {
    justifyContent: "flex-end",
  }

})

const ConfidenceRateButton = styled(Button)({
  width: '75px',
  height: '75px',
  fontSize: "25px",
  borderRadius: "10px",
  background: "rgba(255, 255, 255, 0.10)",
  boxShadow: "none",
  fontFamily: "CircularStd-Medium",
  "&:hover": {
    backgroundColor: '#2875EA',
  }
})

const ConfidenceRateDescriptionsBlock = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: "100%",
  color: "#A1A6AD",
  fontSize: "15px",
  fontFamily: "CircularStd-Medium",
  "@media (max-width:375px)": {
    flexDirection: "column",
    alignSelf: "stretch",
    maxWidth: "10ch",
  }
})

function Confidence({ promptString, questionString }: { promptString: string, questionString: string}) {
  const [value, setValue] = React.useState<number | undefined>();

  const handleChange = (value: number) => {
    setValue(value);
    console.log('change', value)
  };

  return (
    <Container>
      <QuestionText >{promptString} having difficult conversations in the workplace?</QuestionText>
      <ConfidenceRateContainer>
        <ConfidenceRateButtonsBlock>
          {[1,2,3,4,5,6,7,8,9,10].map((value) =>
            <ConfidenceRateButton
              variant="contained"
              onClick={() => handleChange(value)}
              key={value}
            >
              {value}
            </ConfidenceRateButton>
          )}
        </ConfidenceRateButtonsBlock>
        <ConfidenceRateDescriptionsBlock>
          <div>Not confident</div>
          <div>Extremely confident</div>
        </ConfidenceRateDescriptionsBlock>
      </ConfidenceRateContainer>
    </Container>
  );
}

export default Confidence;
