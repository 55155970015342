import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import {
  Box,
  Typography,
  IconButton
} from '@mui/material';
import levenshtein from "js-levenshtein";
import RecordVoice from "./svg/RecordVoice";
import Microphone from "./svg/Microphone";

const TextContainer = styled(Box)({
  textAlign: 'left',
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
})

const MainHeader = styled(Typography)({
  fontWeight: 700,
  fontSize: "clamp(1.875rem, 1.3248rem + 2.3474vw, 3.4375rem)",
  letterSpacing: '-1.65px',
})

const RegularText = styled(Typography)({
  fontWeight: 450,
  fontSize: '20px',
  letterSpacing: '-0.2px',
  color: '#D5D7D8',
  fontFamily: "CircularStd-Light"
})

const SecondaryText = styled(Typography)({
  fontSize: "15px",
  fontWeight: 450,
  color: '#A1A6AD',
  letterSpacing: '-0.15px',
  paddingY: '50xp',
  marginY: '10px',
  fontFamily: "CircularStd-Light"
})

const VoiceOver = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  borderRadius: "15px",
  border: "3px solid rgba(255, 255, 255, 0.10)",
  padding: "15px 25px",
  width: "fit-content",
})

const VoiceOverText = styled(Typography)({
  marginLeft:'10px',
  fontWeight: 'bold',
})

const RecordButton = styled(IconButton)({
  width: '65px',
  height: '65px',
  borderRadius: '50%',
})

const targetString = 'I can speak and my words are powerful.';

function VoiceTest() {
  const [transcript, setTranscript] = useState("");
  const [isListening, setIsListening] = useState(false);
  const [distance, setDistance] = useState(null);

  useEffect(() => {
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    const recognition = new SpeechRecognition();

    recognition.onstart = () => {
      console.log("Voice is being recorded...");
    };

    recognition.onresult = (event) => {
      const resultTranscript = event.results[0][0].transcript;
      setTranscript(resultTranscript);
      const levDistance = levenshtein(targetString, resultTranscript);
      setDistance(levDistance);
      // setTranscript(event.results[0][0].transcript);
    };

    if (isListening) {
      recognition.start();
    }

    return () => {
      recognition.stop();
    };
  }, [isListening]);

  const handleListen = () => {
    setIsListening(!isListening);
  };

  return (
    <TextContainer>
      <RecordButton onClick={handleListen}>
        <Microphone />
      </RecordButton>
      <MainHeader>Voice Test</MainHeader>
      <RegularText >This is a voice activated experience. Read the text below out loud, slowly, and clearly.</RegularText>
      <VoiceOver >
        <RecordVoice />
        <VoiceOverText>"I can speak and my words are powerful."</VoiceOverText>
      </VoiceOver>
      <SecondaryText>Please accept the request to activate your device's microphone.</SecondaryText>
      <Typography sx={{ fontWeight: 'bold' }}>
        {transcript}
      </Typography>
      {distance !== null && (
        <Typography sx={{ fontWeight: 'bold' }}>
          Levenshtein distance: {distance}
        </Typography>
      )}
    </TextContainer>
  );
}

export default VoiceTest;