import React, { ReactNode } from "react";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import {
  Box,
  Paper,
  Typography,
  Container,
  Drawer,
  IconButton,
  Button,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import ChevronRight from "./svg/ChevronRight";
import ChevronLeft from "./svg/ChevronLeft";
import LoadingBg from '../images/LoadingBg.jpg';

// Import other necessary components and styles

interface LayoutProps {
  setStep: (step: number) => void;
  children: ReactNode;
  handleLogout: () => void;
  toggleDrawer: () => void;
  drawerOpen: boolean;
  activeStep: number;
  handleBack: () => void;
  handleNext: () => void;
}

const theme = createTheme({
  palette: {
    primary:{
      main: "#2875EA",
      contrastText: 'white',
      dark: '#194485',
    },
    mode: "dark",
  },
  typography: {
    fontFamily: "CircularStd"
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          "@media (max-width:375px)": {
            "&::before": {
              filter: "blur(40px)",
            }
          },
          "@media (min-width: 375px)": {
            padding: "clamp(30px, 5%, 50px)",
          },
          "@media (min-width: 1200px)": {
            maxWidth: "100%",
          }
        },
      },
    },
  },
});

const Background = styled(Container)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexDirection: 'column',
  padding: "clamp(30px, 5%, 50px)",
  width: "100%",
  minHeight: "100vh",
  "&::before": {
    content: "''",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7),rgba(0, 0, 0, 0.7)), url(${LoadingBg})`
  }
});

const Foreground = styled(Paper)({
  width: "min(100%, 1094px)",
  marginTop: '30px',
  borderRadius: "25px",
  boxSizing: "border-box",
  padding: "min(10%, 80px)",
  backgroundColor: "rgba(255, 255, 255, 0.10)",
  backdropFilter: 'blur(40px)',
  flex: 1,
  display: "flex",
  justifyContent: 'center',
  flexDirection: "column",
  "@media (max-width:375px)":{
    padding: "0",
    backgroundColor: "inherit",
    backdropFilter: "blur(0)",
    backgroundImage: "none",
    boxShadow: "none",
    justifyContent: 'flex-start',
    borderRadius: "0",
  }
});

const Option = styled(Box)({
  padding: "20px",
  borderRadius: "5px",
  backgroundColor: "rgb(190, 190, 190)",
  margin: "5px",
  color: "black",
});

const CustomDrawer = styled(Drawer)({
  flexShrink: 0,
  "& .MuiDrawer-paper": {
    width: "min(100%, 400px)",
    marginTop: "10px",
    marginX: "20px",
    transform: "translateX(20px)",
    padding: "25px",
    height: "calc(100% - 40px)",
    borderRadius: "10px",
    backgroundColor: "rgb(200, 200, 200)",
  },
});

const BackButton = styled(Button)({
  backgroundColor: "rgba(255, 255, 255, 0.10)",
  borderRadius: "50%",
  width: "65px",
  height: "65px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
  },
});

const NextButton = styled(Button)(({ theme })=>({
  backgroundColor: theme.palette.primary.main,
  borderRadius: "50px",
  padding: "19px 40px",
  color: theme.palette.primary.contrastText,
  fontWeight: 700,
  fontSize: "20px",
  lineHeight: "27px",
  textTransform: 'none',
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const Layout = ({
  children,
  setStep,
  handleLogout,
  toggleDrawer,
  drawerOpen,
  activeStep,
  handleBack,
  handleNext,
}: LayoutProps) => (
  <ThemeProvider theme={theme}>
    <CssBaseline />

    {/* Background and Drawer */}
    <Background>
      <Box
        sx={{
          alignSelf: "flex-start",
        }}
      >
        <IconButton onClick={toggleDrawer}>
          <MenuIcon />
        </IconButton>
      </Box>
      <CustomDrawer open={drawerOpen} sx={{ width: "%" }}>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton onClick={toggleDrawer}>
            <CloseIcon sx={{ color: "black" }} />
          </IconButton>
        </Box>
        <Typography
          sx={{ color: "black", fontWeight: "bold", fontSize: "40px" }}
        >
          Menu
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
          }}
        >
          <Option>
            <Button sx={{ color: "black", fontWeight: "bold" }} onClick={() => {toggleDrawer(); setStep(0)}}>
              Exit to main menu
            </Button>
          </Option>
          <Option>
            <Button sx={{ color: "black", fontWeight: "bold" }} onClick={() => {toggleDrawer(); setStep(2)}}>
              Restart experience
            </Button>
          </Option>
          <Option>
            <Button sx={{ color: "black", fontWeight: "bold" }}>Support</Button>
          </Option>
          <Option>
            <Button sx={{ color: "black", fontWeight: "bold" }} onClick={handleLogout}>Sign out</Button>
          </Option>
        </Box>
      </CustomDrawer>

      {/* Foreground */}
      <Foreground>{children}</Foreground>

      {/* Bottom Navigation */}
      <Box
        sx={{
          width: "min(100%, 1094px)",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingTop: "30px",
          boxSizing: "border-box",
        }}
      >
        <BackButton style={{ 'opacity': activeStep === 0 ? 0 : 1 }} disabled={activeStep === 0} onClick={handleBack}>
          <ChevronLeft />
        </BackButton>

        <NextButton onClick={handleNext}>Next &nbsp;&nbsp;<ChevronRight/></NextButton>
      </Box>
    </Background>
  </ThemeProvider>
);

export default Layout;
