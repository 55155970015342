import React, { useEffect, useRef } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { styled } from '@mui/material/styles';

const ExperienceContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100vh',
    padding: '50px',
    gap: '30px'
});

const InstructionBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '20px',
    padding: '40px',
    border: '2px solid gray',
    borderRadius: '10px',
    width: '80%',
    textAlign: 'center'
});

const OptionBox = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    gap: '20px',
    justifyContent: 'center',
    width: '100%'
});

function ExperienceIntro({ moduleName }: { moduleName: string }) {
    const audioRef = useRef<HTMLAudioElement | null>(null);

    useEffect(() => {
      const audioElement = audioRef.current;

      const loadAudio = async () => {
        if (audioElement) {
          try {
            // Define the audio source URL based on the moduleName
            const response = await fetch(`${process.env.REACT_APP_ASSETS_URL}/modules/${moduleName}/onboarding_vo.mp3`);

            if (!response.ok) {
              throw new Error(`Failed to fetch audio: ${response.status}`);
            }

            // Create a blob from the response data
            const audioBlob = await response.blob();

            // Create an object URL from the blob
            const audioSource = URL.createObjectURL(audioBlob);

            // Set the audio source
            audioElement.src = audioSource;

            // Autoplay the audio when the component mounts
            audioElement.play().catch((error) => {
              console.error('Audio playback error:', error);
            });
          } catch (error) {
            console.error('Error loading audio:', error);
          }
        }
      };

      loadAudio();
    }, [moduleName]);

    return (
        <ExperienceContainer>
            <Typography variant="h3" sx={{ fontWeight: 'bold' }}>About the experience</Typography>
            <InstructionBox>
                <Typography variant="h6">At each stage of the conversation you will be presented with 3 options for how to respond.</Typography>
                <Typography>Choose the choice that you think is best and read it out loud.</Typography>
                <Typography color="gray" fontSize="small"><i>We strongly recommend headphones</i></Typography>
            </InstructionBox>
            <OptionBox>
                <Button variant="outlined">Option A</Button>
                <Button variant="outlined">Option B</Button>
                <Button variant="outlined">Option C</Button>
            </OptionBox>
            <audio ref={audioRef} controls style={{ display: 'none' }} />
        </ExperienceContainer>
    );
}

export default ExperienceIntro;
