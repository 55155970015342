import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function ChevronLeft() {
    return (
        <SvgIcon>
            <svg width="27" height="29" viewBox="0 0 27 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path id="Vector" d="M14.875 7.11328L8.125 14.1133L14.875 21.1133" stroke="white" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgIcon>
    );
}
