import React from "react";
import { createTheme, styled } from '@mui/material/styles';
import {
  Box,
  Typography,
  Button
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { green, red } from '@mui/material/colors';

// ... [other imports, if any]
import { Moment } from '../types';

const FeedbackContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '20px',
  padding: '20px',
  maxWidth: '600px', // Adjust as needed
  // background: 'rgba(255, 255, 255, 0.8)', // Light background
  borderRadius: '10px',
});

const FeedbackCard = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  padding: '10px',
  borderRadius: '10px',
  width: '100%',
  border: 'gray',
});

function FeedbackSummary({ keyMoments, moduleScript }: { keyMoments: Set<number>, moduleScript: Moment[] | null }) {

  return (
    <FeedbackContainer>
      <Typography variant="h4">How did you do?</Typography>
      <Typography variant="h5" gutterBottom>Maintain Professional Boundaries</Typography>

      <FeedbackCard color={green[400]}>
        <CheckCircleIcon sx={{ color: green[400] }} />
        <Typography>First opportunity: Good work establishing an atmosphere of trust to help accelerate a culture of inclusion and belonging.</Typography>
      </FeedbackCard>

      <FeedbackCard color={red[400]}>
        <CancelIcon sx={{ color: red[400] }} />
        <Typography>Second opportunity: Good work calling out subjective decision making to help dismantle barriers to economic opportunity.</Typography>
      </FeedbackCard>

      <FeedbackCard color={red[400]}>
        <CancelIcon sx={{ color: red[400] }} />
        <Typography>Third opportunity: Good work establishing an atmosphere of trust to help accelerate a culture of inclusion and belonging.</Typography>
      </FeedbackCard>

      <Button variant="contained" color="primary">Exit</Button>
    </FeedbackContainer>
  );
}

export default FeedbackSummary;