import React, { useEffect, useRef } from "react";
import { styled } from '@mui/material/styles';
import {
  Box,
  Typography,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { green, red } from '@mui/material/colors';

import './CharacterIntro.css';

import alisha from '../images/characters/alisha.png';
import emma from '../images/characters/emma.png';
import evelyn from '../images/characters/evelyn.png';
import jimmy from '../images/characters/jimmy.png';
import parker from '../images/characters/parker.png';
import sally from '../images/characters/sally.png';
import sana from '../images/characters/sana.png';
import will from '../images/characters/will.png';

const characters = {
  'Alisha': alisha,
  'Emma': emma,
  'Evelyn': evelyn,
  'Jimmy': jimmy,
  'Parker': parker,
  'Sally': sally,
  'Sana': sana,
  'Will': will
}

// const Textcontainer = styled(Box)({
//   textAlign: 'left',
//   maxWidth: '100%',
//   minWidth: '80%',
//   display: 'flex',
//   flexDirection: 'column',
//   justifyContent: 'center',
//   height: '100%',
//   marginTop: '15%',
// })

const Textcontainer = styled(Box)({
  textAlign: 'left',
  maxWidth: '80%',
  display: 'flex',
  flexDirection: 'column',
  // justifyContent: 'space-',
  height: '100%',
  margin: '15%',
  marginRight: '40%',
  gap: '20px'
})

const Skill = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    border: '2px solid gray',
    padding: '20px',
    borderRadius: '5px',
    // margin: '15px',
  })

function CharacterIntro({
  communicationStyleA,
  communicationStyleB,
  communicationStyleC,
  characterName,
  moduleName
 }: {
  communicationStyleA: string,
  communicationStyleB: string,
  communicationStyleC: string,
  characterName: string,
  moduleName: string
 }) {
  const audioRef = useRef<HTMLAudioElement | null>(null);

  useEffect(() => {
    const audioElement = audioRef.current;

    const loadAudio = async () => {
      if (audioElement) {
        try {
          // Define the audio source URL based on the moduleName
          const response = await fetch(`${process.env.REACT_APP_ASSETS_URL}/modules/${moduleName}/character_vo.mp3`);

          if (!response.ok) {
            throw new Error(`Failed to fetch audio: ${response.status}`);
          }

          // Create a blob from the response data
          const audioBlob = await response.blob();

          // Create an object URL from the blob
          const audioSource = URL.createObjectURL(audioBlob);

          // Set the audio source
          audioElement.src = audioSource;

          // Autoplay the audio when the component mounts
          audioElement.play().catch((error) => {
            console.error('Audio playback error:', error);
          });
        } catch (error) {
          console.error('Error loading audio:', error);
        }
      }
    };

    loadAudio();
  }, [moduleName]);

  return (
    <Textcontainer>
      <Typography variant="h3" sx={{ fontWeight: 'bold', paddingBottom: '20px' }}>{characterName}</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '20px' }}>
        <img src={characters[characterName as keyof typeof characters]} alt="" style={{ borderRadius: '50%', width: '200px', height: '200px', objectFit: 'cover' }} />
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <Skill>
            <CheckCircleIcon sx={{ color: green[400] }} />
            <Typography>Be {communicationStyleA.toLocaleLowerCase()}</Typography>
          </Skill>
          <Skill>
            <CheckCircleIcon sx={{ color: red[400] }} />
            <Typography>Avoid being {communicationStyleB.toLocaleLowerCase()}</Typography>
          </Skill>
          <Skill>
            <CheckCircleIcon sx={{ color: red[400] }} />
            <Typography>Avoid being {communicationStyleC.toLocaleLowerCase()}</Typography>
          </Skill>
        </Box>
      </Box>
      <audio ref={audioRef} controls style={{ display: 'none' }} />
    </Textcontainer>
  );
}

export default CharacterIntro;