import React, { useEffect, useRef } from "react";
import { styled } from '@mui/material/styles';
import {
  Box,
  Typography,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '35px',
})

const MainHeader = styled(Typography)({
  fontWeight: 700,
  fontSize: "clamp(1.875rem, 1.3248rem + 2.3474vw, 3.4375rem)",
  letterSpacing: '-1.65px',
})

const RegularText = styled(Typography)({
  fontWeight: 450,
  fontSize: '20px',
  lineHeight: "26px",
  letterSpacing: '-0.2px',
  fontFamily: "CircularStd-Medium",
  maxWidth: '50ch',
  width: "min(100%, 500px)",
})

const Skill = styled(Box)({
  borderRadius: "15px",
  border: "3px solid rgba(255, 255, 255, 0.10)",
  display: 'flex',
  alignItems: 'center',
  padding: '25px 40px',
  "@media (max-width:375px)": {
    padding: '25px',
    alignItems: 'flex-start',
  }
})

const CheckIcon = styled(CheckCircleIcon)({
    color: "#20BF6F",
    marginRight: '20px',
})

function LearningObjective({
  learningObjective,
  summary,
  moduleName
}: { learningObjective: string, summary: string, moduleName: string }) {
  const audioRef = useRef<HTMLAudioElement | null>(null);

  useEffect(() => {
    const audioElement = audioRef.current;

    const loadAudio = async () => {
      if (audioElement) {
        try {
          // Define the audio source URL based on the moduleName
          const response = await fetch(`${process.env.REACT_APP_ASSETS_URL}/modules/${moduleName}/learning_objective_vo.mp3`);

          if (!response.ok) {
            throw new Error(`Failed to fetch audio: ${response.status}`);
          }

          // Create a blob from the response data
          const audioBlob = await response.blob();

          // Create an object URL from the blob
          const audioSource = URL.createObjectURL(audioBlob);

          // Set the audio source
          audioElement.src = audioSource;

          // Autoplay the audio when the component mounts
          audioElement.play().catch((error) => {
            console.error('Audio playback error:', error);
          });
        } catch (error) {
          console.error('Error loading audio:', error);
        }
      }
    };

    loadAudio();
  }, [moduleName]);

  return (
    <Container>
      <MainHeader>Skills for Success</MainHeader>
      <RegularText>{summary}</RegularText>
      <Skill>
        <CheckIcon />
        <RegularText>{learningObjective}</RegularText>
      </Skill>
      <audio ref={audioRef} controls style={{ display: 'none' }} />
    </Container>
  );
}

export default LearningObjective;